import React from 'react'
import axios from 'axios'

import { logEvent } from '../utils/analytics'

const CCWaitlist = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { success: false, error: false, email: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ email: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()

    if (this.state.email) {
      const userObject = {
        list_ids: ['aed8ce75-1c92-4c3d-b846-97f106234f57'],
        contacts: [
          {
            email: this.state.email
          }
        ]
      }

      axios
        .put('https://api.sendgrid.com/v3/marketing/contacts', userObject, {
          headers: {
            authorization: `Bearer ${process.env.GATSBY_SENDGRID_API ||
              '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'}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          if (response.data && response.data.job_id) {
            this.setState({
              success: true,
              error: false
            })
            const object = {
              category: 'Environment',
              action: 'Submitted Environment Notify Form'
            }

            logEvent(object)
          }
        })
        .catch(error => {
          this.setState({
            success: false,
            error: true
          })
          const object = {
            category: 'Environment',
            action: 'Error Environment Notify Form',
            value: error
          }

          logEvent(object)
        })
    }
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`dib relative w-100 f5 ${this.props.className}`}
      >
        <div className="dib flex-l justify-between middle-xs w-100">
          <input
            className="dib relative bg-near-white w-100 w-70-l pa3 br-pill mr0 mr3-l mb3 mb0-l"
            placeholder="Enter your email address"
            onChange={this.handleChange}
            value={this.state.email}
            type="email"
          />
          {this.state.success ? (
            <button
              className="dib relative w-100 w-30-l bg-washed-green black tc pa3 br-pill b link dim pointer"
              type="submit"
              disabled
            >
              Talk soon ✌️
            </button>
          ) : (
            <button
              className="dib relative w-100 w-30-l bg-brand-pink brand-red tc pa3 br-pill b link dim pointer"
              type="submit"
            >
              Get notified
            </button>
          )}
        </div>
        <div className="dib relative w-100 mt3">
          {this.state.success ? (
            <p className="dib relative f7 green">
              We'll be in touch. Thanks for joining our movement!
            </p>
          ) : null}
          {this.state.error ? (
            <p className="dib relative f7 brand-red">Uh oh, something went wrong. Try again!</p>
          ) : null}
        </div>
      </form>
    )
  }
}

export default CCWaitlist
