import React from 'react'
import { graphql } from 'gatsby'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// Components
import GraphQLErrorList from '../components/graphql-error-list'
// import PageHeader from '../components/page-header'
import CCWaitlist from '../components/cc-waitlist'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query EnvironmentPageQuery {
    environment: sanityEnvironment(_id: { regex: "/(drafts.|)environmentPage/" }) {
      title
      title1
      body
      slide1Image {
        asset {
          _id
        }
      }
      title2
      body2
      how
      cb1
      cb1Text
      cb2
      cb2Text
      inclusion {
        asset {
          _id
        }
      }
      vc
      vcDescription
      vcCTALink
      vcCTA
      vcImage {
        asset {
          _id
        }
      }
    }
  }
`

// Classes
const HeadingClass = 'dib relative w-100 w-60-ns f2 fw5 mt0 mb2 black'
const ParagraphClass = 'dib relative w-100 measure-wide inherit fw4 mb4'
const ButtonClass = 'dib relative w-100 w-auto-l brand-red tc ph3 pv2 br-pill b link dim pointer'

const EnvironmentPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const environment = (data || {}).environment

  if (!environment) {
    throw new Error(
      'Missing "Environment" page data. Open the studio at http://localhost:3333 and add "Environment" page data and restart the development server.'
    )
  }

  // const SectionWhite = () => (
  //   <section className="dt relative vh-100 w-100 lh-copy overflow-hidden f5 white overlay-gradient">
  //     <div className="dtc v-mid w-100 relative z-1">
  //       <PageHeader
  //         description={environment.body}
  //         heading={environment.title1}
  //         href="#card"
  //         white
  //       />
  //     </div>
  //     <img
  //       className="dib absolute right-0 left-0 top-0 h-100 fit"
  //       src={imageUrlFor(buildImageObj(environment.slide1Image))}
  //       alt={environment.title1}
  //     />
  //   </section>
  // )

  // const SectionNormal = () => (
  //   <section className="dt relative vh-100 w-100 lh-copy overflow-hidden black f5">
  //     <div className="dtc v-mid w-100 relative">
  //       <PageHeader description={environment.body} heading={environment.title1} href="#card" />
  //     </div>
  //   </section>
  // )

  // const chooseSection = () => {
  //   if (
  //     environment.slide1Image &&
  //     environment.slide1Image.asset &&
  //     environment.slide1Image.asset._id
  //   ) {
  //     return <SectionWhite />
  //   } else {
  //     return <SectionNormal />
  //   }
  // }

  return (
    <Layout>
      <SEO title={environment.title} description={environment.body} />
      <article className="environment lh-copy" id="environment">
        {/* First section */}
        <section className="dt relative vh-100 w-100 bg-white gray lh-copy overflow-hidden f5">
          <div className="dtc v-mid w-100 relative">
            <div className="db center mw8 ph4 pv6 h-100">
              <div className="dib flex-l justify-between items-center h-100">
                <div className="dib relative w-100 w-60-l v-mid mb4 mb0-l mr0 mr5-l">
                  <h1 className="dib relative w-100 f2 fw5 mt0 mb2 black">{environment.title1}</h1>
                  <p className={`${ParagraphClass} preline`}>{environment.body}</p>
                  <img
                    className="dib dn-l relative w-100 pv4"
                    src="/img/cc-white.svg"
                    alt="Credit card"
                  />
                  <div className="dib relative w-100">
                    <CCWaitlist />
                  </div>
                </div>
                <div className="dn dib-l relative w-100 w-40-l bg-brand-pink h-100">
                  <img
                    className="dib relative w-100 h-100 v-mid pa4"
                    src="/img/cc-white.svg"
                    alt="Credit card"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section section */}
        <section className="dib relative w-100 bg-white gray lh-copy f5 overflow-hidden" id="card">
          <div className="db center mw8 ph4 pt6 pb4">
            <h2 className={HeadingClass}>{environment.title2}</h2>
            <p className={`${ParagraphClass} preline`}>{environment.body2}</p>
            {/* How */}
            <div className="dib relative w-100">
              <hr className="dib relative w3 br-pill o-20 bb bw1 b--black mv4 mh0" />
              <div className="dib relative w-100">
                <h3 className="dib relative w-100 measure-wide f3 fw4 brand-green">
                  {environment.how}
                </h3>
                <div className="dib flex-l justify-between w-100 items-top mt4">
                  <div className="dib relative w-100 w-auto-l mb4 mb0-l mr0 mr4-l">
                    <strong className="dib relative w-auto black f4 fw4">{environment.cb1}</strong>
                    <p className="dib relative w-100 measure f5 fw4 dark-gray">
                      {environment.cb1Text}
                    </p>
                  </div>
                  <div className="dib relative w-100 w-auto-l mr0 mr4-l">
                    <strong className="dib relative w-auto black f4 fw4">{environment.cb2}</strong>
                    <p className="dib relative w-100 measure f5 fw4 dark-gray">
                      {environment.cb2Text}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Images */}
          <div className="flex justify-between top-xs relative w-100">
            <img
              className="dib relative dual-images fit w-100 mr3 mr4-l"
              alt="Socially responsible investing"
              src={imageUrlFor(buildImageObj(environment.inclusion[0]))}
            />
            <img
              className="dib relative dual-images fit"
              alt="Socially responsible investing"
              src={imageUrlFor(buildImageObj(environment.inclusion[1]))}
            />
          </div>
        </section>
        {/* VC Section */}
        <section className="dib relative w-100 bg-white gray lh-copy f5 overflow-hidden" id="join">
          <div className="db center mw8 ph4 pt6 pb2">
            <h4 className={HeadingClass}>{environment.vc}</h4>
            <p className={`${ParagraphClass} preline`}>{environment.vcDescription}</p>
            <div className="dib relative w-100 mb4">
              <a
                className={`bg-brand-pink ${ButtonClass}`}
                href={`mailto:${environment.vcCTALink}`}
                rel="noopener noreferrer"
              >
                {environment.vcCTA}
              </a>
            </div>
            <div className="dib relative w-100">
              <img
                className="dib relative w-100 fit"
                alt="Help us build the future"
                src={imageUrlFor(buildImageObj(environment.vcImage))}
              />
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default EnvironmentPage
